<template>
  <div class="view-root view-content edit-note-container bg-white">
    <div class="flex justify-between items-center mb-6">
      <p class="title">Add Refff</p>
      <Avatar />
    </div>
    <div class="flex gap-6">
      <div class="edit-content-container">
        <div class="flex items-center justify-between">
          <div class="custom-btn back" @click="goBack">
            <img class="btn-icon" src="../../assets/back.png" />
            Back
          </div>
          <div class="flex items-center gap-2">
            <div class="custom-btn" @click="saveNote">
              <img class="btn-icon" src="../../assets/check.png" />Save
            </div>
          </div>
        </div>
        <div class="edit-note">
          <div class="note-input">
            <el-input
              v-model="title"
              class="note-title"
              placeholder="Please input title..."
            />

            <div class="flex items-center justify-between mb-4">
              <div class="tags-container">
                <div
                  v-for="(tag, index) in labels.filter((i) => !!i)"
                  class="tag"
                  :key="index"
                  :style="{
                    color: TagColorList[index].color,
                    background: TagColorList[index].background,
                    cursor: 'pointer',
                  }"
                  @click="deleteLabel(index)"
                >
                  <div class="tag-text">{{ tag }}</div>
                </div>
              </div>
              <div
                class="custom-btn ml-6"
                style="height: 34px; border-radius: 8px; padding: 0 12px"
                @click="showTagModal = true"
              >
                <img src="../../assets/add.png" class="btn-icon" />
              </div>
            </div>

            <el-input
              type="textarea"
              v-model="content"
              autosize
              placeholder="Please input content..."
            />
          </div>
        </div>
      </div>
      <!-- <div
        class="timeline-container"
        v-if="remindTimes && remindTimes.length > 0"
      >
        <p class="timeline-title">Wake-up mails</p>
        <div class="timeline-item-wrapper mt-6">
          <div
            class="flex items-center justify-between timeline-item"
            v-for="(item, index) in remindTimes"
            :key="index"
            :class="item.class"
          >
            <div class="detail flex items-center">
              <div class="dot"></div>
              <p>{{ item.remind_label }}</p>
            </div>
            <p>{{ $dayjs(item.remind_time * 1000).format("MMMM D, YYYY") }}</p>
          </div>
        </div>
      </div> -->
    </div>
    <Modal :show="showTagModal" @close="closeTagModal">
      <div slot="title">Add New Tags</div>
      <div slot="content" class="delete-content">
        <el-input v-model="tempTag" placeholder="Please input tag" />
      </div>
      <div slot="footer">
        <div class="w-full custom-btn" @click="addTag">Save</div>
      </div>
    </Modal>
  </div>
</template>
<script>
import "../../assets/common/common.css";
import "../../assets/common/font.css";
import { encryptValue } from "../../utils/common";
import { initTags, updateNote } from "../../api/reff";
import { getUserId } from "../../utils/store";
import { Loading } from "element-ui";
import Avatar from "../../components/Avatar.vue";
import Modal from "../../components/Modal.vue";
import { TagColorList } from "../../utils/common";

export default {
  name: "index",
  components: {
    Avatar,
    Modal
  },
  data() {
    return {
      TagColorList,
      showTime: "",
      title: "",
      content: "",
      labels: [],
      showAddTagDialog: false,
      tempTag: "",
      showReviewDialog: false,
      reviewNote: "yes",
      loadingInstance: undefined,
      showTagModal: false
    };
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    this.showTime = this.formatMonthToEng(month) + " " + day + ", " + year;
  },
  methods: {
    alertReviewNote() {
      this.doSaveNoteInfo();
      // this.$confirm('Do you need to review the note later?', 'Tips', {
      //   confirmButtonText: 'YES',
      //   cancelButtonText: 'NO',
      //   type: 'warning',
      //   callback: action => {
      //     if (action == 'confirm') {
      //       this.reviewNote = 'yes'
      //     }
      //     this.doSaveNoteInfo()
      //   }
      // })
    },
    deleteLabel(index) {
      this.labels.splice(index, 1);
    },
    closeTagModal() {
      this.tempTag = "";
      this.showTagModal = false;
    },
    async doSaveNoteInfo() {
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: "Data submission in progress, please wait",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      let encryptTitle = encryptValue(this.title);
      let encryptContent = encryptValue(this.content);
      let encryptLabels = [];
      if (this.labels.length > 0) {
        encryptLabels = this.encryptLabels(this.labels);
      } else {
        let aiTags = await initTags(this.content);
        let tempTags = aiTags.data.data;
        if (tempTags && tempTags != "") {
          let contents = tempTags.split("\n");
          let originalLabels = [];
          contents.forEach((item) => {
            let length = item.length;
            let start = item.indexOf(".") + 1;
            let tag = item.substring(start, length);
            originalLabels.push(tag.trim());
          });
          encryptLabels = this.encryptLabels(originalLabels);
        }
      }
      let params = {
        id: "",
        user_id: getUserId(),
        title: encryptTitle,
        content: encryptContent,
        reviewNote: this.reviewNote,
        labels: encryptLabels,
      };
      updateNote(params).then((res) => {
        this.loadingInstance.close();
        this.$message.success("Add success~");
        this.goBack();
      });
    },
    saveNote() {
      if (this.title == "") {
        this.$message.error("Please input title");
        return;
      }
      if (this.content == "") {
        this.$message.error("Please input content");
        return;
      }
      this.alertReviewNote();
    },
    encryptLabels(labels) {
      let newArray = [];
      for (let i = 0; i < labels.length; i++) {
        let encryptLabel = encryptValue(labels[i]);
        newArray.push(encryptLabel);
      }
      return newArray;
    },
    addTag() {
      if (this.tempTag == "") {
        this.$message.error("Please input");
        return;
      }
      this.labels.push(this.tempTag);
      this.tempTag = "";
      this.showTagModal = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "Jan";
        case 2:
          return "Feb";
        case 3:
          return "Mar";
        case 4:
          return "Apr";
        case 5:
          return "May";
        case 6:
          return "Jun";
        case 7:
          return "Jul";
        case 8:
          return "Aug";
        case 9:
          return "Sep";
        case 10:
          return "Oct";
        case 11:
          return "Nov";
        case 12:
          return "Dec";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.edit-note-container {
  padding: 32px;
  overflow-y: scroll;
  position: relative;
  .title {
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: #1a202c;
  }
  .timeline-container {
    width: 360px;
    height: fit-content;
    font-family: "Poppins";
    border: 1px solid #edf2f7;
    border-radius: 12px;
    padding: 24px;
    .timeline-title {
      font-size: 22px;
      font-weight: 600;
      color: #1a202c;
    }
    .timeline-item-wrapper {
      .timeline-item {
        font-size: 15px;
        margin-bottom: 12px;
        &.done {
          color: #a0aec0;
          .detail {
            .dot {
              background: #a0aec0;
            }
          }
        }
        &.during {
          color: #ec652b;
          .detail {
            .dot {
              background: #ec652b;
            }
          }
        }
        
        .detail {
          font-weight: 600;
          .dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin-right: 12px;
            background: #1a202c;
          }
        }
      }
    }
  }
  .edit-content-container {
    flex: 1;
    .edit-note {
      border-radius: 16px;
      border: 1px solid #edf2f7;
      padding: 24px;
      margin-top: 24px;
      min-height: calc(100vh - 202px);
    }
    .note-info {
      color: #005a64;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      .view-icon {
        width: 14px;
        height: 14px;
      }
    }
    .note-input {
      .note-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        font-family: "Poppins";
        color: #1a202c;
        width: 100%;
      }
      .note-content {
        width: 100%;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 500;
        font-family: Manrope;
        color: #718096;
      }
      &::v-deep .el-input__inner {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        background: transparent;
        font-family: "Poppins";
        color: #1a202c;
        margin: 16px 0;
        font-weight: 600 !important;
      }
      &::v-deep .el-textarea__inner {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 500;
        background: transparent;
        font-family: Manrope;
        color: #718096;
        cursor: auto !important;
      }
    }
  }
  .custom-btn {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    color: white;
    font-family: Poppins;
    background: #ec652b;
    height: 48px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;
    .btn-icon {
      width: 16px;
      height: 16px;
    }
    &.delete-btn {
      background: #fe5024;
    }
    &.back {
      color: #ec652b;
      border: 2px solid #ec652b;
      height: 48px;
      background: white;
    }
  }
}
.delete-content {
  font-size: 14px;
  color: #a0aec0;
  margin: 24px 0;
  width: 100%;
  text-align: center;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .tag {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    border-radius: 8px;
    font-size: 12px;
    padding: 8px 12px;
    .tag-text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
